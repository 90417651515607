import React, { useEffect, useState, useCallback } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./StakeholderDetails.module.css";
import arrow from "../../assets/image/arrow-back.png";
import { NavLink } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import Caregiver from "./Caregiver";
import Subscription from "./Subscription";
import AdminIndependicareApi from "../../Helpers/Api";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import Stackholder from "./Stakeholder";
import AssignedSenior from "./AssignedSenior";
function CaregiversDetails() {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const [caregiverDetail, setCaregiveDetail] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageno, setPageNo] = useState(1);
  const [paginationType, setPaginationType] = useState();
  useEffect(() => {
    AdminIndependicareApi.caregiveDetail(userToken, id, pageno).then(
      (response) => {
        const ResponseCode = STATUS_MSG[response?.data.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setCaregiveDetail(response?.data?.caregiverDetail);
          setData(response?.data);
        }
      }
    );
  }, [id, pageno, dispatch, navigate]);
  const tabChange = (e) => {
    setPageNo(1);
  };

  return (
    <>
      <Header />
      <div className={styles.stackDetailsMain}>
        <div className={styles.stackDetailsInner}>
          <div className={styles.arrowImage}>
            <NavLink to="/caregivers-list">
              <img src={arrow} alt="arrow"></img>
            </NavLink>
          </div>
          <div className={styles.stackDetailsText}>
            <h1>{t("CAREGIVERS_DETAILS")} </h1>
          </div>
        </div>
        <div></div>
      </div>
      <div className={styles.infoSection}>
        <h3>{t("BASIC_INFO")}</h3>
        <div className={styles.infoSectionBackground}>
          <div className={styles.mainInfo_sec}>
            <div className={styles.userInfo}>
              <h4>{t("NAME")}</h4>
              <p className={styles.textuppercase}>{caregiverDetail?.name}</p>
            </div>

            <div className={styles.userInfo1}>
              <h4>{t("EMAIL")}</h4>
              <p>{caregiverDetail?.email}</p>
            </div>
          </div>
          <div className={styles.mainInfo_sec}>
            <div className={styles.userInfo}>
              <h4>{t("CONTACT_NUMBER")}</h4>
              <p>
                {" "}
                {caregiverDetail?.dial_code} {caregiverDetail?.phone_number}
              </p>
            </div>

            <div className={styles.userInfo1}>
              <h4>{t("LOCATION")}</h4>
              <p>{caregiverDetail?.street_address}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.stackDetailsTab}>
        <Tabs
          defaultActiveKey="stakeholder"
          id="fill-tab-example"
          fill
          onSelect={(e) => tabChange(e)}>
          <Tab eventKey="stakeholder" title="Stakeholder">
            <Stackholder data={data} setPageNo={setPageNo} />
          </Tab>
          <Tab eventKey="assignedSenior" title="Assigned Senior">
            <AssignedSenior data={data} setPageNo={setPageNo} />
          </Tab>
          <Tab eventKey="subscription" title="Subscription Plan">
            <Subscription />
          </Tab>
        </Tabs>
      </div>
      <Footer />
    </>
  );
}

export default CaregiversDetails;
