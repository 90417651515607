import React, { useState } from "react";
import Header from "../Header/Header";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { NavLink } from "react-router-dom";
import styles from "../Home/Home.module.css";
import { useTranslation } from "react-i18next";
import MockData from "../MockData/Mockdata";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import Setting from "../../assets/image/Setting.png";
function Home() {
  const { t } = useTranslation();
  const sections = MockData();
  const [count, setCount] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.userToken);
  useEffect(() => {
    AdminIndependicareApi.dashboardCount(userToken).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setCount(response?.data);
      }
    });
  }, []);
  return (
    <>
      <div>
        <Header></Header>
        <Container>
          <div className={styles.homeMainSetion}>
            <Row className={styles.customRow}>
              <Col lg={12}>
                {sections.map((section, index) => (
                  <div key={index} className={styles.stakeholdersMain}>
                    <div className={styles.stackInnerdiv}>
                      <div className={styles.stakeImage}>
                        <img src={section.imgSrc} alt={section.alt}></img>
                      </div>
                      <div className={styles.stakeText}>
                        <NavLink to={section.link}>
                          <h1>{section.text}</h1>
                        </NavLink>
                      </div>
                    </div>
                    <div className={styles.siteCircleimg}>
                      <span className={styles.circleCount}></span>
                      <div className={styles.CircularProgressbar}>
                        <CircularProgressbar
                          className="demo111111"
                          value={count[section.countKey] || 0}
                          maxValue={1000}
                          text={`${count[section.countKey] || 0}`}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className={styles.stakeholdersMain}>
                  <div className={styles.stackInnerdiv}>
                    <div className={styles.stakeImage}>
                      <img src={Setting} alt="alt"></img>
                    </div>
                    <div className={styles.stakeText}>
                      <NavLink to="/caregiver-sources">
                        <h1>{t("SETTING")}</h1>
                      </NavLink>
                    </div>
                  </div>
                  <div className={styles.siteCircleimg}>
                    <span className={styles.circleCount}></span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}
export default Home;
