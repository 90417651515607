import React from 'react'
import styles from './StakeholderDetails.module.css';
function Subscription() {
    return (
        <>
            <div className='subScriptionMain'>
                <div className={styles.subScriptionInner}>
                    <div className={styles.subScriptionText}>
                        <div className={styles.peremiumText}>
                            <h1>Premium</h1>
                        </div>
                        <div className={styles.durationText}>
                            <p>Duration</p>
                            <p>25/5/2025</p>
                        </div>
                    </div>
                    <div className={styles.subScriptionBottom}>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text</p>
                        <h1>$250</h1>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Subscription