import React, { useEffect, useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
import { userLogoutClear } from "../../redux/slices/userSlice";
function StatusCaregiverSources({
  statusshow,
  handleStatusClose,
  caregiverStatus,
  handleGetData,
  resourceId,
}) {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.user.userToken);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const getStatusCaregiverSources = (id) => {
    AdminIndependicareApi.statusCaregiverSource(userToken, id).then(
      (response) => {
        handleGetData();
        handleStatusClose();
      }
    );
  };

  return (
    <Modal show={statusshow} onHide={handleStatusClose}>
      <Modal.Header>
        <Modal.Title>{t("ALERT")}</Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleStatusClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caregiverSourcePopup}>
          {caregiverStatus === ACTIVE_TYPE.IS_ACTIVE ? (
            <p>
              {t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_CAREGIVER_SOURCES?")}
            </p>
          ) : (
            <p>
              {t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_CAREGIVER_SOURCES?")}
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.addNewTitle}>
        <Button
          variant=""
          onClick={() => {
            getStatusCaregiverSources(resourceId);
          }}>
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StatusCaregiverSources;
