import stake_image from "../../assets/image/stake_image .png";
import Persons from "../../assets/image/Persons .png";
import Caregivers from "../../assets/image/Caregivers.png";
import Setting from "../../assets/image/Setting.png";
import sites from "../../assets/image/sites.png";
import site_circle from "../../assets/image/site_circle.png";
import stakeholders_image from "../../assets/image/stakeholders_image.png";
import persons_new from "../../assets/image/persons_new.png";
import caregivers_new from "../../assets/image/caregivers_new.png";
import { useTranslation } from "react-i18next";
import Audio from "../../assets/image/Audio.png";
export const stakeHoldersData = [
  {
    siteId: 1001,
    start_Date: "10/05/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 28346651001,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Premium",
  },
  {
    siteId: 1002,
    start_Date: "08/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 546567821002,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Gold",
  },
  {
    siteId: 1003,
    start_Date: "05/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 64646461003,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
  {
    siteId: 1004,
    start_Date: "01/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 10006571004,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
  {
    siteId: 1005,
    start_Date: "18/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 20202001005,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Gold",
  },
  {
    siteId: 1006,
    start_Date: "24/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 38833831006,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Premium",
  },
  {
    siteId: 1007,
    start_Date: "28/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 43333121007,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
  {
    siteId: 1008,
    start_Date: "30/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 99449941008,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
  {
    siteId: 1009,
    start_Date: "02/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 44844841007,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Gold",
  },
  {
    siteId: 1010,
    start_Date: "30/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 99449941008,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
  {
    siteId: 1011,
    start_Date: "30/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 99449941008,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
  {
    siteId: 1012,
    start_Date: "30/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 99449941008,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
  {
    siteId: 1013,
    start_Date: "30/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    stakeholderId: 99449941008,
    stakeholder_Contact_Info: "+91 283-4665-100",
    plan: "Silver",
  },
];
export const stackHolderDetails = [
  {
    siteId: "Site Title1",
    start_Date: "10/05/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St, New York, NY 10011, USA",
    notes: "Lorem Ipsum dollar ",
  },
  {
    siteId: "Site Title2",
    start_Date: "08/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St, New York, NY 10011, USA",
    notes: "Lorem Ipsum dollar ",
  },
  {
    siteId: "Site Title3",
    start_Date: "05/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St, New York, NY 10011, USA",
    notes: "Lorem Ipsum dollar ",
  },
  {
    siteId: "Site Title4",
    start_Date: "01/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St, New York, NY 10011, USA",
    notes: "Lorem Ipsum dollar ",
  },

  {
    siteId: "Site Title4",
    start_Date: "02/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St, New York, NY 10011, USA",
    notes: "Lorem Ipsum dollar ",
  },
  {
    siteId: "Site Title4",
    start_Date: "11/05/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St, New York, NY 10011, USA",
    notes: "Lorem Ipsum dollar ",
  },
  {
    siteId: "Site Title4",
    start_Date: "01/03/24",
    independicare_Representative: "Lorem Ipsum dollar dummy text",
    rader_Location: "15th Street 47 W 18th St, New York, NY 10011, USA",
    notes: "Lorem Ipsum dollar ",
  },
];

export const caregiverData = [
  {
    name: "Vicktor Tilml",
    email: "vicktor.timly@gmail.com",
    seniors: "Adam Smith",
    relation: "Father",
  },
  {
    name: "Nick Thomson",
    email: "nick.thomson@gmail.com	",
    seniors: "Ross Taylor",
    relation: "Mother",
  },
  {
    name: "Charlie Jackson",
    email: "charlie.jackson@gmail.com",
    seniors: "John Smith",
    relation: "Father",
  },
  {
    name: "Jonathn Major",
    email: "jonathn.major@gmail.com",
    seniors: "Adam Roy",
    relation: "Brother",
  },
  {
    name: "Mark Spector",
    email: "mark.spector@gmail.com",
    seniors: "No senior assign",
    relation: "Mother",
  },
];

// home page mockdata
const MockData = () => {
  const { t } = useTranslation();

  const sections = [
    {
      imgSrc: stake_image,
      text: t("STAKEHOLDERS"),
      link: "/stakholderlist",
      alt: "stake_image",
      circleImgSrc: stakeholders_image,
      countKey: "stakeholdersCount",
    },
    {
      imgSrc: sites,
      text: t("SITES"),
      link: "JavaScript:void(0)",
      alt: "sites",
      countKey: "sitesCount",
    },
    {
      imgSrc: Persons,
      text: t("PERSONS"),
      link: "JavaScript:void(0)",
      alt: "person",
      countKey: "personsCount",
    },
    {
      imgSrc: Caregivers,
      text: t("CAREGIVERS"),
      link: "/caregivers-list",
      alt: "Caregivers",
      countKey: "caregiversCount",
    },
    // {
    //   imgSrc: Setting,
    //   text: t("SETTING"),
    //   link: "/caregiver-sources",
    //   alt: "Caregivers",
    //   // circleImgSrc: caregivers_new,
    // },
  ];

  return sections;
};

export default MockData;

export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];

export const ACTIVE_TYPE = {
  IS_ACTIVE: 1,
  IS_INACTIVE: 0,
};
// Events Type
export const Events = [
  {
    date: "10/05/24",
    time: "12:30:00 PM",
    eventType: "Meditation",
    reminder: "Lorem Ipsum is simply dummy text Lorem",
  },
  {
    date: "11/05/24",
    time: "01:00:00 PM",
    eventType: "Yoga",
    reminder: "Reminder for yoga session",
  },
  {
    date: "12/05/24",
    time: "02:00:00 PM",
    eventType: "Workout",
    reminder: "Reminder for workout session",
  },
];

export const DailyEvents = [
  {
    time: "12:30:00 PM",
    eventType: "Meditation",
    reminder: "Lorem Ipsum is simply dummy text Lorem",
  },
  {
    time: "01:00:00 PM",
    eventType: "Yoga",
    reminder: "Reminder for yoga session",
  },
  {
    time: "02:00:00 PM",
    eventType: "Workout",
    reminder: "Reminder for workout session",
  },
];

export const tag = [
  {
    tag_id: 11254456,
    tag_locaton: "Lorem Ipsum dollar dummy",
    prompt_intro_tts: "Prompt intro TTS",
    use_tts_for_prompt: "Lorem Ipsum is simply",
    Reminder: "Command String Lorem Ipsum is simply dummy text",
    tag_audio: Audio,
    notes: "Lorem Ipsum dollar dummy",
  },
  {
    tag_id: 11254456,
    tag_locaton: "Lorem Ipsum dollar dummy",
    prompt_intro_tts: "Prompt intro TTS",
    use_tts_for_prompt: "Lorem Ipsum is simply",
    Reminder: "Command String Lorem Ipsum is simply dummy text",
    tag_audio: Audio,
    notes: "Lorem Ipsum dollar dummy",
  },
  {
    tag_id: 11254456,
    tag_locaton: "Lorem Ipsum dollar dummy",
    prompt_intro_tts: "Prompt intro TTS",
    use_tts_for_prompt: "Lorem Ipsum is simply",
    Reminder: "Command String Lorem Ipsum is simply dummy text",
    tag_audio: Audio,
    notes: "Lorem Ipsum dollar dummy",
  },
  {
    tag_id: 11254456,
    tag_locaton: "Lorem Ipsum dollar dummy",
    prompt_intro_tts: "Prompt intro TTS",
    use_tts_for_prompt: "Lorem Ipsum is simply",
    Reminder: "Command String Lorem Ipsum is simply dummy text",
    tag_audio: Audio,
    notes: "Lorem Ipsum dollar dummy",
  },
];
