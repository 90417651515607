import React, { useEffect, useState } from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import horizontal from "../../assets/image/horizontal.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
function Caregiver({ data, setPageNo }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listcargiver, setListCargiver] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setListCargiver(data.addedCaregiverList);
      const pages = Math.ceil(data.addedCaregiverCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };
  const handleNameClick = (id) => {
    // navigate(`/caregivers-details/${id}`);
    window.open(`/caregivers-details/${id}`, "_blank");
  };

  return (
    <>
      {listcargiver?.length !== 0 ? (
        <div className={styles.caregiverlist}>
          <Table striped bordered className={styles.stackDetailsTable}>
            <thead>
              <tr>
                <th>{t("NAME")}</th>
                <th>{t("EMAIL")}</th>
                <th>{t("PHONE_NUMBER")}</th>
                <th>{"Seniors"} </th>
              </tr>
            </thead>
            <tbody>
              {listcargiver?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td
                      onClick={() => handleNameClick(item.caregiver_id)}
                      className={`${styles.caregiverName} ${styles.personName}`}
                      target="_blank">
                      {item.name}
                    </td>
                    <td>{item.email}</td>
                    <td>
                      {item.dial_code} {item.phone_number}
                    </td>
                    <td className={styles.personName}>
                      {item.person_names.map((val, ind) => {
                        if (item.person_names.length == 1) {
                          return val;
                        }
                        if (ind < item.person_names.length - 1) {
                          return val + ",";
                        }
                        // For the last item, just return the value without a comma
                        return val;
                      })}
                      {item.person_names.length == 0 && (
                        <span className={styles.noSeniorassign}>
                          No senior assign
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
      )}
      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}
export default Caregiver;
