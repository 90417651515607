import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import styles from "../Footer/Footer.module.css";
function Footer() {
  const { t } = useTranslation();
  let currentYear = new Date().getFullYear();
  return (
    <>
      <div className="fixedFotter">
        <div className={styles.footerMainDiv}>
          <div className={styles.copyRight}>
            <h2>
              @{currentYear} {t("INDEPENDI_CARE")}
            </h2>
            <ul className={styles.ptc}>
              <li>
                <a href="#">{t("PRIVACY_POLICY")}</a>
              </li>
              <li>
                <a href="#">{t("TERMS_&_CONDITIONS")}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
