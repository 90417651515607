import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "../Footer/Footer";
import styles from "../Header/Header.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import profilePhoto from "../../assets/image/profilePhoto.png";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import Image from "react-bootstrap/Image";
import AdminIndependicareApi from "../../Helpers/Api";
import { useTranslation } from "react-i18next";
import arrow from "../../assets/image/arrow-back.png";
import { NavLink, useNavigate } from "react-router-dom";
import { CustomInputField } from "../CustomInputField/CustomInputField";
import { Form, Button } from "react-bootstrap";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import { fetchUserProfile } from "../../redux/slices/userSlice";
import Loader from "../Loader/Loader";
function Profile() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState, setValue, readOnly } =
    useForm({
      mode: "onChange",
    });
  const { t } = useTranslation();
  const { userToken, userData, profileImg } = useSelector(
    (state) => state.user
  );
  console.log(userData, "userData");
  const [adminDetails, setAdminDetails] = useState({});
  const [editprofile, setEditProfile] = useState(true);
  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleEditClick = () => {
    setEditProfile(true);
  };

  // const getProfileImage = () => {
  //   dispatch(userProfle(userToken)).then((response) => {
  //     const adminDetails = response?.adminDetails;
  //     setAdminDetails(adminDetails);
  //     setValue("FullName", adminDetails?.fullname);
  //     setValue("email", adminDetails?.email);
  //     setPreviewImage(adminDetails?.profile_img);

  //     // dispatch(setProfileImage(adminDetails?.profile_img));
  //   });
  // };

  const fetchProfileData = () => {
    dispatch(fetchUserProfile(userToken)).then((response) => {
      // const adminDetails = response?.adminDetails;
      const res = response.payload;
      const data = res.data.adminDetails;
      console.log(data, "dtaa");
      setAdminDetails(userData);
      setValue("FullName", data?.fullname);
      setValue("email", data?.email);
      setPreviewImage(data?.profile_img);
    });
  };

  const onSubmit = async (formdata) => {
    const PostData = new FormData();
    PostData.append("full_name", formdata.FullName);
    PostData.append("email", formdata.email);
    if (selectedFile) {
      PostData.append("profile_img", selectedFile);
    }
    setIsLoading(true);
    AdminIndependicareApi.updateProfile(userToken, PostData).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("PROFILE_UPDATE_SUCCESSFULLY"), "success");
          setEditProfile(true);
          fetchProfileData();
        } else if (
          response?.code == STATUS_CODE.VALIDATION_ERROR ||
          response?.data?.code == STATUS_CODE.VALIDATION_ERROR
        ) {
          Toster(response?.code?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
    // Optional: Create a preview of the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleBack = () => {
    // setEditProfile(true);
    navigate(-1);
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}

      <div className={styles.editProfileSection}>
        <div className={styles.stackInner}>
          <div className={styles.arrowImage}>
            <NavLink to="/home">
              <img src={arrow} alt="arrow"></img>
            </NavLink>
          </div>
          <div className={styles.stakeHoldersText}>
            <h1>{t("EDIT_PROFILE")}</h1>
          </div>
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.editPrfolieFrom1}>
          <div className={styles.editPrfolieFrom}>
            <CustomInputField
              type="type"
              PlaceHolder={t("ENTER_FULL_NAME")}
              RegisterName="FullName"
              register={register}
              formState={formState}
              label={t("FULL_NAME")}
              // onChange={(e) => {
              //   setValue("FullName", e.target.value.replace(/\s/g, ""), {
              //     shouldValidate: true,
              //   });
              // }}
              setValue={setValue}
            />
            <CustomInputField
              type="type"
              PlaceHolder={t("ENTER_EMAIL_ADDRESS")}
              RegisterName="email"
              register={register}
              formState={formState}
              label={t("EMAIL_ADDRESS")}
              // onChange={(e) => {
              //   setValue("email", e.target.value.replace(/\s/g, " "), {
              //     shouldValidate: true,
              //   });
              // }}
              readOnly={true}
              disabled={true}
              setValue={setValue}
            />
            <input type="file" onChange={handleFileChange} accept="image/*" />
            <div className={styles.preViewImageMain}>
              {previewImage && (
                <img
                  src={previewImage}
                  alt="Preview"
                  className={styles.prviewImage}
                />
              )}
            </div>
          </div>
          <div className={styles.updateProfileButton}>
            <Button variant="" type="submit">
              {t("UPDATE_PROFILE")}
            </Button>
          </div>
        </Form>
      </div>

      <Footer />
    </>
  );
}

export default Profile;
