import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

class AdminIndependicareApi {
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    const headers = { ...AdminIndependicareApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  //Login===

  static async login(data) {
    let res = await this.request("/admin/login", data, "post");
    return res;
  }
  // send code ===
  static async sendCode(data) {
    let res = await this.request("/admin/send-code", data, "post");
    return res;
  }

  //reset password ===

  static async resetPassword(data) {
    let res = await this.request("/admin/reset-password", data, "post");
    return res;
  }

  // logout ==
  static async logOut(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/admin/logout", {}, "post", header);
    return res;
  }
  // chnage password api
  static async changePassword(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      "/admin/change-password",
      data,
      "post",
      header
    );
    return res;
  }
  // fetch profile api
  static async fetchProfile(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request("/admin/fetch-profile", {}, "get", header);
    return res;
  }
  // update profile api

  static async updateProfile(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request("/admin/update-profile", data, "post", header);
    return res;
  }

  // add api caregiver-source
  static async caregiverSource(url, method, data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/admin/${url}`, data, method, header);
    return res;
  }

  // list caregiver list
  static async fetchCaregiverSource(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      "/admin/settings/fetch-caregiver-source",
      {},
      "get",
      header
    );
    return res;
  }
  //
  static async fetchCaregiverSourceDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/settings/fetch-source-details/${id}`,
      {},
      "get",
      header
    );
    return res;
  }
  // delete api  deleteCaregiverSource
  static async deleteCaregiverSource(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/settings/delete-caregiver-source/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }
  // carrgiver status api
  static async statusCaregiverSource(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/settings/update-source-status/${id}`,
      {},
      "put",
      header
    );
    return res;
  }

  // add location api
  // static async addLocation(url, method, data, authToken) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(`/admin/${url}`, data, method, header);
  //   return res;
  // }

  // list location list
  // static async fetchLocationType(authToken, searchTerm) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/fetch-location-type?search=${searchTerm}`,
  //     {},
  //     "get",
  //     header
  //   );
  //   return res;
  // }
  //fetch location detail
  // static async fetchLocationeDetail(authToken, id) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/fetch-location-details/${id}`,
  //     {},
  //     "get",
  //     header
  //   );
  //   return res;
  // }
  // location delete api
  // static async deleteLocationType(authToken, id) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/delete-loaction-type/${id}`,
  //     {},
  //     "delete",
  //     header
  //   );
  //   return res;
  // }
  //location Status api
  // static async statusLocation(authToken, id) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/update-location-status/${id}`,
  //     {},
  //     "put",
  //     header
  //   );
  //   return res;
  // }
  // sublocation add api
  // static async addSublocation(url, method, data, authToken) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(`/admin/${url}`, data, method, header);
  //   return res;
  // }

  // list sublocation list
  // static async fetchSublocationType(authToken, searchTerm) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/fetch-sublocation-type?search=${searchTerm}`,
  //     {},
  //     "get",
  //     header
  //   );
  //   return res;
  // }
  // //fetch  sublocation detail
  // static async fetchSubLocationeDetail(authToken, id) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/fetch-sublocation-details/${id}`,
  //     {},
  //     "get",
  //     header
  //   );
  //   return res;
  // }

  //sublocation detail api
  // static async fetchSubLocationeDetail(authToken, id) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/fetch-sublocation-details/${id}`,
  //     {},
  //     "get",
  //     header
  //   );
  //   return res;
  // }

  // sublocation delete api
  // static async deletSubLocationType(authToken, id) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/delete-sublocation-type/${id}`,
  //     {},
  //     "delete",
  //     header
  //   );
  //   return res;
  // }
  // status sublocation
  // static async statusSubLocation(authToken, id) {
  //   let header = { "access-token": ` ${authToken}` };
  //   let res = await this.request(
  //     `/admin/settings/update-sublocation-status/${id}`,
  //     {},
  //     "put",
  //     header
  //   );
  //   return res;
  // }

  //  stakehloder List api
  static async fetchStakeholderList(authToken, pageno, searchTerm) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/stakeholder/fetch-stakeholder-list?page_no=${pageno}&search=${searchTerm}`,
      {},
      "get",
      header
    );
    return res;
  }
  //
  // caregiver list
  static async fetchCaregiverList(authToken, pageno, searchTerm) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/caregiver/fetch-caregiver-list?page_no=${pageno}&search=${searchTerm}`,
      {},
      "get",
      header
    );
    return res;
  }
  // stackholder detail api
  static async stakeholderDetail(
    authToken,
    id,
    pageno = "",
    paginationType = ""
  ) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/stakeholder/fetch-stakeholder-detail/${id}?page_no=${pageno}&pagination_type=${paginationType}`,
      {},
      "get",
      header
    );
    return res;
  }
  //Caregivers detail api

  static async caregiveDetail(
    authToken,
    id,
    pageno = " ",
    paginationType = ""
  ) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/caregiver/fetch-caregiver-detail/${id}?page_no=${pageno}&pagination_type=${paginationType}`,
      {},
      "get",
      header
    );
    return res;
  }
  // privacyPolicy get api
  static async privacyPolicy(authToken, Page) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/settings/fetch-content-page?page_name=${Page}`,
      {},
      "get",
      header
    );
    return res;
  }

  // carrgiver status api
  static async updateprivacyPolicy(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/settings/update-contents`,
      data,
      "put",
      header
    );
    return res;
  }

  // admin seeting dashboard
  // list caregiver list
  static async dashboardCount(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      "/admin/dashboard/dashboard-count",
      {},
      "get",
      header
    );
    return res;
  }

  // deletStackholder delete api
  static async deletStackholder(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/stakeholder/delete-stakeholder/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  // Caregivers delete api
  static async deletCaregivers(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/caregiver/delete-caregiver/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }
  // Stackholder Status
  static async statusStackHolder(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/stakeholder/change-stakeholder-status/${id}`,
      {},
      "get",
      header
    );
    return res;
  }

  // statusCaregivers
  static async statusCaregivers(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/admin/caregiver/change-caregiver-status/${id}`,
      {},
      "get",
      header
    );
    return res;
  }
}

export default AdminIndependicareApi;
