import React, { useEffect, useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import { CustomInputField } from "../CustomInputField/CustomInputField";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import userLogoutClear from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { CustomSeletInputFiled } from "../CustomInputField/CustomInputField";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
function StatusSubLocation({
  statusshow,
  handleStatusClose,
  sublocationStatus,
  subLocationList,
  sublocationId,
  searchTerm,
}) {
  const { register, handleSubmit, reset, formState, setValue, getValues } =
    useForm({
      mode: "onChange",
    });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = useSelector((state) => state.user.userToken);
  const getSubStatusLocation = (id) => {
    AdminIndependicareApi.statusSubLocation(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      subLocationList(searchTerm);
      handleStatusClose();
    });
  };
  return (
    <Modal show={statusshow} onHide={handleStatusClose}>
      <Modal.Header>
        <Modal.Title>{t("ALERT")}</Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleStatusClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caregiverSourcePopup}>
          {sublocationStatus === ACTIVE_TYPE.IS_ACTIVE ? (
            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_SUB_LOCATION")}</p>
          ) : (
            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_SUB_LOCATION")}</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.addNewTitle}>
        <Button variant="" onClick={() => getSubStatusLocation(sublocationId)}>
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StatusSubLocation;
