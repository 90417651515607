import React from "react";
import loader from "../../assets/image/loader.gif";
import styles from "./Loader.module.css";
function Loader() {
  return (
    <>
      <div className={styles.loader}>
        <img src={loader} alt="loading" />
      </div>
    </>
  );
}

export default Loader;
