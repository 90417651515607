import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import styles from "../SettingPermission/Settingpermission.module.css";
import { Dropdown, Table, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import delete_icon from "../../assets/image/delete_icon.svg";
import { useNavigate } from "react-router-dom";
import deleteimage from "../../assets/image/deleteimage.png";
import { userLogoutClear } from "../../redux/slices/userSlice";
import cancelBtn from "../../assets/image/cancelBtn.png";
import Toster from "../../Toster/Toster";
function DeleteCaregiverSourcesModel({
  deleteshow,
  handleDeleteClose,
  handleGetData,
  resourceId,
}) {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = (id) => {
    setIsLoading(true);
    AdminIndependicareApi.deleteCaregiverSource(userToken, id).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("DELETE_CAREGIVER_SOURCES_SUCCESSFULLY"), "success");
          } else if (
            response?.code == STATUS_CODE.VALIDATION_ERROR ||
            response?.data?.code === STATUS_CODE.VALIDATION_ERROR
          ) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
        handleGetData();
        handleDeleteClose();
      }
    );
  };
  return (
    <Modal show={deleteshow} onHide={handleDeleteClose}>
      <Modal.Header>
        <Modal.Title>Alert</Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleDeleteClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caregiverSourcePopup}>
          <img
            src={deleteimage}
            alt="deleteimage"
            className={styles.deleteImageModel}></img>
          <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CAREGIVER_SOURCES?")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.addNewTitle}>
        <Button
          variant=""
          onClick={() => {
            handleDelete(resourceId);
          }}>
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteCaregiverSourcesModel;
