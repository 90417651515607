import React from "react";
import { Form, Controller } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import styles from "../Login/Login.module.css";
import styles from "./CustomInputField.module.css";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import Select from "react-select";
import { faHourglass1 } from "@fortawesome/free-solid-svg-icons";
import MockData from "../MockData/Mockdata";

export const CustomInputField = ({
  type,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  label,
  onChange,
  readOnly,
  disabled = false,
  setValue,
}) => {
  // disable space on key press input
  const handleKeyPress = (e) => {
    // Check if the pressed key is a space
    if (e.key === " ") {
      e.preventDefault();
    }
    if (e.target.value.startsWith(" ")) {
      setValue(e.target.name, e.target.value.trim());
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <Form.Group controlId="" className={styles.loginLable}>
        <Form.Label className={styles.labelUsername}> {label} </Form.Label>
        <Form.Control
          type={type}
          placeholder={PlaceHolder}
          {...register(RegisterName, {
            ...ValidationSchema[RegisterName],
            onChange: (e) => handleKeyPress(e), // Restrict key presses
          })}
          isInvalid={!!formState.errors[RegisterName]}
          classname={styles.loginTabInput}
          readOnly={readOnly}
          disabled={disabled}
        />
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export const CustomPasswordInputField = ({
  PlaceHolder,
  RegisterName,
  register,
  formState,
  label,
  isVisible,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div class={styles.customPasswordfiled}>
        <Form.Group controlId="">
          <Form.Label className={styles.labelUsername}>{label}</Form.Label>
          <Form.Control
            type={isVisible ? "text" : "password"}
            placeholder={PlaceHolder}
            {...register(RegisterName, ValidationSchema[RegisterName])}
            isInvalid={!!formState.errors[RegisterName]}
            onChange={onChange}
            classname={styles.loginTabInput}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors[RegisterName]?.message)}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </>
  );
};

// export function CustomSelectFieldsPredefined({
//   value,
//   onChangeHandler,
//   label,
//   PlaceHolder,
//   RegisterName,
//   register,
//   options,
//   formState,
//   type,
//   onChange,
//   className,
//   onClick,
// }) {
//   const { t } = useTranslation();

//   return (
//     <Form.Group className={className}>
//       <Form.Label className={styles.labelUsername}>{label}</Form.Label>
//       <div className={styles.selectInputFiled}>
//         <Form.Select
//           aria-label="Default select example"
//           {...register(RegisterName, ValidationSchema[RegisterName])}
//           isInvalid={!!formState.errors[RegisterName]}
//           onChange={onChange}
//           onClick={onClick}>
//           <option className={className} value="">
//             {PlaceHolder}
//           </option>
//           {options?.map((option, index) => (
//             <option key={index} value={option.value}>
//               {option.label}
//             </option>
//           ))}
//         </Form.Select>
//         <Form.Control.Feedback type="invalid">
//           {t(formState.errors[RegisterName]?.message)}
//         </Form.Control.Feedback>
//       </div>
//     </Form.Group>
//   );
// }

export const CustomSelect = ({
  label,
  RegisterName,
  register,
  formState,
  onChangeHandler,
  PlaceHolder,
  options,
  disabled,
  defaultValue,
  styleName,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label className={styles.labelUsername}>{label}</Form.Label>
      <div className={styles.selectInputFiled}>
        <Form.Select
          aria-label="Default select example"
          {...register(RegisterName, {
            ...ValidationSchema[RegisterName],
            onChange: onChangeHandler,
          })}
          isInvalid={!!formState.errors[RegisterName]}
          disabled={disabled}>
          <option value={defaultValue} defaultValue>
            {PlaceHolder}
          </option>
          {options &&
            options?.map((item, index) => (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {t(formState.errors[RegisterName]?.message)}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};
