import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./StakeholderDetails.module.css";
import arrow from "../../assets/image/arrow-back.png";
import { NavLink } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import Site from "./Site";
import Person from "./Person";
import Caregiver from "./Caregiver";
import Event from "./Event";
import DailyEvent from "./DailyEvent";
import Subscription from "./Subscription";
import AdminIndependicareApi from "../../Helpers/Api";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toster from "../../Toster/Toster";
import StackHolderDevice from "./StackHolderDevice";
import Loader from "../Loader/Loader";
import Tag from "./Tag";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../redux/slices/userSlice";
function StakeholderDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user.userToken);
  const { id } = useParams();

  const [pageno, setPageNo] = useState(1);
  const [stakeholderDetail, setStakeholderDetail] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    AdminIndependicareApi.stakeholderDetail(
      userToken,
      id,
      pageno
      // paginationType
    ).then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setStakeholderDetail(response?.data?.stakeholderDetail);
        setData(response?.data);
      }
    });
  }, [id, pageno, dispatch, navigate]);

  const tabChange = (e) => {
    setPageNo(1);
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className={styles.stackDetailsMain}>
        <div className={styles.stackDetailsInner}>
          <div className={styles.arrowImage}>
            <NavLink to="/stakholderlist">
              <img src={arrow} alt="arrow"></img>
            </NavLink>
          </div>
          <div className={styles.stackDetailsText}>
            <h1>{t("STAKEHOIDER_DETAILS")} </h1>
          </div>
        </div>
        <div></div>
      </div>
      <div className={styles.infoSection}>
        <h3>{t("BASIC_INFO")}</h3>
        <div className={styles.infoSectionBackground}>
          <div className={styles.mainInfo_sec}>
            <div className={styles.userInfo}>
              <h4>{t("NAME")}</h4>
              <p className={styles.textuppercase}>{stakeholderDetail?.name}</p>
            </div>

            <div className={styles.userInfo1}>
              <h4>{t("EMAIL")}</h4>
              <p>{stakeholderDetail?.email}</p>
            </div>
          </div>
          <div className={styles.mainInfo_sec}>
            <div className={styles.userInfo}>
              <h4>{t("CONTACT_NUMBER")}</h4>
              <p>
                {stakeholderDetail?.dial_code} {stakeholderDetail?.phone_number}
              </p>
            </div>

            <div className={styles.userInfo1}>
              <h4>Location</h4>
              <p>{stakeholderDetail?.street_address}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.stackDetailsTab}>
        <Tabs
          defaultActiveKey="site"
          id="fill-tab-example"
          fill
          onSelect={(e) => tabChange(e)}>
          <Tab eventKey="site" title="Site">
            <Site data={data} setPageNo={setPageNo} />
          </Tab>
          <Tab eventKey="person" title="Person">
            <Person data={data} setPageNo={setPageNo} />
          </Tab>

          <Tab eventKey="caregiver" title="Caregiver">
            <Caregiver data={data} setPageNo={setPageNo} />
          </Tab>
          <Tab eventKey="event" title="Event">
            <Tabs
              defaultActiveKey="event"
              id="justify-tab-example1"
              className="">
              <Tab eventKey="event" title="Event">
                <Event data={data} setPageNo={setPageNo} />
              </Tab>
              <Tab eventKey="dailyEvent" title="Daily Event">
                <DailyEvent data={data} setPageNo={setPageNo} />
              </Tab>
            </Tabs>
          </Tab>
          <Tab eventKey="Tag" title="Tag">
            <Tag data={data} setPageNo={setPageNo} />
          </Tab>
          <Tab eventKey="device" title="Device">
            <StackHolderDevice data={data} setPageNo={setPageNo} />
          </Tab>
          <Tab eventKey="subscription" title="Subscription Plan">
            <Subscription />
          </Tab>
        </Tabs>
      </div>
      <Footer />
    </>
  );
}

export default StakeholderDetails;
