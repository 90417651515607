import React from "react";
import logo from "../../assets/image/logo.png";
import profilePhoto from "../../assets/image/profilePhoto.png";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "../Header/Header.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { CustomPasswordInputField } from "../CustomInputField/CustomInputField";
import { useTranslation } from "react-i18next";
import AdminIndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";
function Header() {
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });
  // popup model code
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profileImg, userToken, currentUser } = useSelector(
    (state) => state.user
  );

  const [isLoading, setIsLoading] = useState(false);
  const logoutHandleFunction = () => {
    setIsLoading(true);
    AdminIndependicareApi.logOut(userToken).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      navigate("/");
      dispatch(userLogoutClear());
    });
  };
  const onSubmit = (data) => {
    const PostData = new FormData();
    PostData.append("old_password", data.OLD_PASSWORD);
    PostData.append("new_password", data.NEW_PASSWORD);
    PostData.append("confirm_password", data.confirmPasswordProfile);
    setIsLoading(true);
    AdminIndependicareApi.changePassword(PostData, userToken).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.code || response?.data?.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("PASSWORD_UPDATED_SUCCESSFULLY"), "success");
            handleClose();
            reset();
            clearErrors();
          } else if (
            response?.code === STATUS_CODE.VALIDATION_ERROR ||
            response?.data?.code === STATUS_CODE.VALIDATION_ERROR
          ) {
            Toster(response?.message || response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.fixedHeader}>
        <div class={styles.headerBackground}>
          <div className={styles.headerImage}>
            <div className={styles.logo}>
              <Link to="/home">
                <img src={logo} alt="Logo" className={styles.logoImage} />
              </Link>
            </div>
            <div>
              <div className={styles.profileImage}>
                <Dropdown className="test">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <div className={styles.headerProfileImage}>
                      <img
                        src={profilePhoto ? profileImg : profilePhoto}
                        alt="Profile"
                        className={styles.profilePhoto}
                      />{" "}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="/profile"
                      className="d-flex justify-content-between align-items-center bg-none">
                      {t("PROFILE")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      href=""
                      className="d-flex justify-content-between align-items-center bg-none"
                      onClick={handleShow}>
                      {t("CHANGE_PASSWORD")}
                    </Dropdown.Item>

                    <Dropdown.Item
                      href=""
                      onClick={logoutHandleFunction}
                      className="d-flex justify-content-between align-items-center bg-none">
                      {t("LOGOUT")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*********Add Title Popup Model******* */}
      <Modal show={show} onHide={handleClose}>
        {isLoading && <Loader />}
        <Modal.Header>
          <Modal.Title>{t("CHANGE_PASSWORD")}</Modal.Title>
          <img
            src={cancelBtn}
            onClick={handleClose}
            className={styles.cancelBtn}></img>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <CustomPasswordInputField
              type="type"
              PlaceHolder={t("OLD_PASSWORD")}
              RegisterName="OLD_PASSWORD"
              register={register}
              formState={formState}
              label={t("LABEL_OLD_PASSWORD")}
              className={styles.modelPopupFiled}
              onChange={(e) => {
                setValue("OLD_PASSWORD", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
              }}></CustomPasswordInputField>
            <CustomPasswordInputField
              type="type"
              PlaceHolder={t("NEW_PASSWORD")}
              RegisterName="NEW_PASSWORD"
              register={register}
              formState={formState}
              label={t("LABEL_NEW_PASSWORD")}
              className={styles.modelPopupFiled}
              onChange={(e) => {
                setValue("NEW_PASSWORD", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
              }}></CustomPasswordInputField>
            <CustomPasswordInputField
              type="type"
              PlaceHolder={t("CHNAGE_CONFIRM_PASSWORD")}
              RegisterName="confirmPasswordProfile"
              register={register}
              formState={formState}
              label={t("LABEL_CONFIRM_PASSWORD")}
              className={styles.modelPopupFiled}
              onChange={(e) => {
                setValue(
                  "confirmPasswordProfile",
                  e.target.value.replace(/\s/g, ""),
                  {
                    shouldValidate: true,
                  }
                );
              }}></CustomPasswordInputField>
          </Modal.Body>
          <Modal.Footer className={styles.upDatePassoword}>
            <Button variant="" type="submit">
              {t("UPDATE_PASSWORD")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Header;
