import React, { useEffect, useState } from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
function StackHolderDevice({ data, setPageNo }) {
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  const [stdevicelist, setDevicesList] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setDevicesList(data.deviceList);
      const pages = Math.ceil(data.deviceCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };
  return (
    <>
      {stdevicelist?.length !== 0 ? (
        <div className={styles.evntType}>
          <Table striped bordered className={styles.stackDetailsTable}>
            <thead>
              <tr>
                <th>{t("DEVICE_TITLE")}</th>
                <th>{t("PERSON_NAMES")}</th>
                <th>{t("MOBILE_DEVICE_NAME")}</th>
                <th>{t("DEVICE_SERIAL_NUMBER")}</th>
                <th>{t("MAINTENANCE_DATE")}</th>
              </tr>
            </thead>
            <tbody>
              {stdevicelist?.map((item, index) => (
                <tr key={index}>
                  <td>{item.device_title ? item.device_title : "NA"}</td>
                  <td>{item.person_names ? item.person_names : "NA"}</td>
                  <td>
                    {item.mobile_device_name ? item.mobile_device_name : "NA"}
                  </td>
                  <td>
                    {item.device_serial_number
                      ? item.device_serial_number
                      : "NA"}
                  </td>
                  <td>
                    {" "}
                    {format(
                      new Date(
                        item.maintenance_date ? item.maintenance_date : "NA"
                      ),
                      "MM/dd/yyyy"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
      )}
      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default StackHolderDevice;
