import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import styles from "../Login/Login.module.css";
import logo from "../../assets/image/logo.png";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import {
  CustomInputField,
  CustomPasswordInputField,
} from "../CustomInputField/CustomInputField";
import { useForm } from "react-hook-form";
import AdminIndependicareApi from "../../Helpers/Api";
import Toster from "../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import Vector from "../../assets/image/Vector.png";
import Loader from "../Loader/Loader";
function ForgotPassword() {
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (formdata) => {
    const PostData = new FormData();
    PostData.append("email", formdata.EMAIL);
    setIsLoading(true);
    AdminIndependicareApi.sendCode(PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("SEND_OTP_TO_THE_REGISTERED_MAIL"), "success");
        navigate("/reset-password", { state: { userEmail: formdata.EMAIL } });
      } else if (
        response?.code == STATUS_CODE.VALIDATION_ERROR ||
        response?.data?.code == STATUS_CODE.VALIDATION_ERROR
      ) {
        Toster(response?.code?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };
  return (
    <>
      <div className={styles.loginContainer}>
        {isLoading && <Loader />}
        <div className={styles.loginIndependiCareLogo}>
          <div className={styles.loginImage}>
            <img src={logo} className={styles.Loginimage}></img>
          </div>
          <div className={styles.loginFrombackground}>
            <div className={styles.fotGotepassowtdtext}>
              <h1>{t("FORGOT_PASSWORD")}</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>

            <div className={styles.forgotPasswordFiled}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <CustomInputField
                  type="type"
                  PlaceHolder={t("ENTER_EMAIL_ADDRESS")}
                  RegisterName="EMAIL"
                  register={register}
                  formState={formState}
                  label={t("EMAIL_ADDRESS")}
                  // onChange={(e) => {
                  //   setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                  //     shouldValidate: true,
                  //   });
                  // }}
                  setValue={setValue}></CustomInputField>

                <div className={styles.backButtonmain}>
                  <div className={styles.backButtonLogin}>
                    <div className={styles.backArrow}>
                      <img src={Vector}></img>
                    </div>
                    <div className={styles.backSingText}>
                      <Link to="/">
                        <p>{t("BACK_TO_SING_IN")}</p>
                      </Link>
                    </div>
                  </div>

                  <Button type="submit" className={styles.sendLinkBtn}>
                    {t("SEND_LINK")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
