import React, { useEffect, useState, useCallback } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import styles from "../SettingPermission/Settingpermission.module.css";
import arrow from "../../assets/image/arrow-back.png";
import horizontal from "../../assets/image/horizontal.png";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import search from "../../assets/image/search.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Dropdown, Table } from "react-bootstrap";
import delete_icon from "../../assets/image/delete_icon.svg";
import Edit from "../../assets/image/Edit.svg";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Loader from "../Loader/Loader";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
import AddSubLocation from "./AddSubLocation";
import StatusSubLocation from "./StatusSubLocation";
import DeleteSubLocationModel from "./DeleteSubLocationModel";
import debounce from "debounce";
function SubLocationType() {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });
  const [locations, setLocations] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [sublocation, setSubLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userToken = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [sublocationId, setSubLocationId] = useState();
  // delete popup funtion
  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  // serch bar states
  const [searchTerm, setSearchTerm] = useState("");
  // chnageStaus popup funtion
  const [statusshow, setStatusShow] = useState(false);
  const handleStatusClose = () => setStatusShow(false);
  const handleStatusShow = () => setStatusShow(true);
  const [sublocationStatus, setSubLocationStatus] = useState(false);

  const handleChange = (e) => {
    setValue("Location", e.target.value);
    // setIsAnyChange(true);
  };
  const formattedOptions = locations.map((location) => ({
    value: location.id,
    label: location.title,
  }));

  useEffect(() => {
    AdminIndependicareApi.fetchLocationType(userToken, searchTerm).then(
      (response) => {
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setLocations(response?.data?.loaction_type);
        }
      }
    );
  }, []);

  useEffect(() => {
    subLocationList(searchTerm);
  }, []);

  const debouncedStackHolder = useCallback(
    debounce((searchTerm) => {
      subLocationList(searchTerm);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    debouncedStackHolder(newSearchTerm);
  };
  // sublocation list api
  const subLocationList = (searchTerm) => {
    setIsLoading(true);
    AdminIndependicareApi.fetchSublocationType(userToken, searchTerm).then(
      (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setSubLocation(response?.data?.subloactionType);
        }
      }
    );
  };

  // deail api call
  useEffect(() => {
    if (isEdit && sublocationId) {
      AdminIndependicareApi.fetchSubLocationeDetail(
        userToken,
        sublocationId
      ).then((response) => {
        setValue("TITLE", response?.data?.sublocation_type?.title);
        setSelectedOption({
          value: response?.data?.sublocation_type?.location_type_id,
          label: response?.data?.sublocation_type?.location_title,
        });
      });
    } else {
      setValue("TITLE", "");
      reset();
    }
  }, [isEdit, sublocationId]);

  return (
    <>
      <div className={styles.settingMainSection}>
        {isLoading && <Loader />}
        <div className={styles.mainSidebar}>
          <div className={styles.seetingLeftSidebar}>
            <Sidebar />
          </div>
          {/******************RightSide bar*****************/}
          <div className={styles.seetingRightSidebar}>
            <Header />
            <div className={styles.sechSeetingmain}>
              <div className={styles.seetingArrowImage}>
                <NavLink to="/setting-permission">
                  <img
                    src={arrow}
                    className={styles.seetingBackArrow}
                    alt="arrow"></img>
                </NavLink>
                <div className={styles.serchIconImage}>
                  <Form.Control
                    size="sm"
                    type="search"
                    placeholder={t("PLACRHOLDER_SEARCH")}
                    value={searchTerm}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <img
                    src={search}
                    alt="serchIcon"
                    className={styles.settingSerchIcon}></img>
                </div>
              </div>
              <div className={styles.addNewButton}>
                <Button
                  variant=""
                  type="submit"
                  onClick={() => {
                    handleShow();
                    reset();
                    setSubLocationId(false);
                    setIsEdit(false);
                  }}>
                  {t("ADD_NEW")}
                </Button>
              </div>
            </div>
            {sublocation?.length !== 0 ? (
              <div className={styles.settingPermissionTable}>
                <Table className="customTable" striped bordered>
                  {isLoading && <Loader />}
                  <thead>
                    <tr>
                      <th className={styles.settingBackground}>{t("TITLE")}</th>
                      <th className={styles.settingBackground}>
                        {t("LOCATION_TITLE")}
                      </th>
                      <th className={styles.settingBackground}>
                        {t("STATUS")}
                      </th>

                      <th className={styles.settingBackgroundAction}>
                        {t("ACTION")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sublocation?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.title}</td>
                          <td>{item.location_title}</td>
                          <td className={styles.caregiverSourceText}>
                            {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                              ? "active"
                              : "inactive"}
                          </td>
                          <td className={styles.settingDotImage}>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className={styles.doteImage}>
                                <img
                                  src={horizontal}
                                  alt="horizontal"
                                  className={styles.careGiverDoteImage}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => {
                                    handleShow(index);
                                    setIsEdit(true);
                                    setSubLocationId(item.id);
                                    // setLocations(item.id);
                                  }}
                                  className={styles.caregiverdropdownItem}>
                                  <span>{t("EDIT")}</span>
                                  <img
                                    src={Edit}
                                    alt="Edit"
                                    className={styles.icon}
                                  />
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  className={styles.caregiverdropdownItem}
                                  onClick={() => {
                                    handleDeleteShow();
                                    setSubLocationId(item.id);
                                  }}>
                                  <span>{t("DELETE")}</span>
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    className={styles.icon}
                                  />
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => {
                                    handleStatusShow();
                                    setSubLocationId(item.id);
                                    setSubLocationStatus(item.is_active);
                                  }}
                                  className={styles.caregiverdropdownItem}>
                                  <span>
                                    {item.is_active === 1
                                      ? "inactive"
                                      : "active"}
                                  </span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              !isLoading && (
                <p className={styles.listRecoredText}>
                  {t("RECORD_NOT_FOUND")}
                </p>
              )
            )}
          </div>
        </div>
      </div>
      {/*********Add Title Popup Model******* */}
      <AddSubLocation
        show={show}
        handleClose={handleClose}
        isEdit={isEdit}
        sublocationId={sublocationId}
        handleChange={handleChange}
        formattedOptions={formattedOptions}
        selectedOption={selectedOption}
        subLocationList={subLocationList}
        setSelectedOption={setSelectedOption}
        searchTerm={searchTerm}
      />
      {/**********delete popupModel */}

      <DeleteSubLocationModel
        deleteshow={deleteshow}
        handleDeleteClose={handleDeleteClose}
        sublocationId={sublocationId}
        subLocationList={subLocationList}
        searchTerm={searchTerm}
      />
      {/********chnage the staus active inactive  popup*/}
      <StatusSubLocation
        statusshow={statusshow}
        handleStatusClose={handleStatusClose}
        sublocationStatus={sublocationStatus}
        subLocationList={subLocationList}
        sublocationId={sublocationId}
        searchTerm={searchTerm}
      />
      <Footer />
    </>
  );
}

export default SubLocationType;
