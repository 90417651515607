import React, { useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../redux/slices/userSlice";
import deleteimage from "../../assets/image/deleteimage.png";
import cancelBtn from "../../assets/image/cancelBtn.png";
import Modal from "react-bootstrap/Modal";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
import Toster from "../../Toster/Toster";
function CaregiverStatus({
  statusshow,
  handleStatusClose,
  caregiversId,
  caregiverStatus,
  searchTerm,
  caregiver,
  pageno,
}) {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const getStatusStack = (id) => {
    AdminIndependicareApi.statusCaregivers(userToken, id).then((response) => {
      console.log("res1", response);
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        caregiver(pageno, searchTerm);
        handleStatusClose();
      }
    });
  };
  return (
    <Modal show={statusshow} onHide={handleStatusClose}>
      <Modal.Header>
        <Modal.Title>{t("ALERT")}</Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleStatusClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caregiverSourcePopup}>
          {caregiverStatus === ACTIVE_TYPE.IS_ACTIVE ? (
            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_CAREGIVERS")}</p>
          ) : (
            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_CAREGIVERS")}</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.addNewTitle}>
        <Button variant="" onClick={() => getStatusStack(caregiversId)}>
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CaregiverStatus;
