import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import styles from "../Login/Login.module.css";
import logo from "../../assets/image/logo.png";
import { useTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import {
  CustomInputField,
  CustomPasswordInputField,
} from "../CustomInputField/CustomInputField";
import { useForm } from "react-hook-form";
import Toster from "../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import { fetchUserProfile, userLogin } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
function Login() {
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserProfileData = (token) => {
    dispatch(fetchUserProfile(token)).then((response) => {});
  };

  const onSubmit = async (formdata) => {
    setIsLoading(true);
    const PostData = new FormData();
    PostData.append("email", formdata.EMAIL);
    PostData.append("password", formdata.PASSWORD);
    dispatch(userLogin(PostData)).then((data) => {
      setIsLoading(false);
      const response = data.payload;
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code == STATUS_CODE.SUCCESS) {
        navigate("/home");
        fetchUserProfileData(response.data.token);
      } else if (
        response?.code == STATUS_CODE.VALIDATION_ERROR ||
        response?.data?.code == STATUS_CODE.VALIDATION_ERROR
      ) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.loginContainer}>
        <div className={styles.loginIndependiCareLogo}>
          <div className={styles.loginImage}>
            <img src={logo} className={styles.Loginimage}></img>
          </div>
          <div className={styles.loginFrombackground}>
            <div className={styles.loginFromtext}>
              <h1>{t("ADMIN_LOGIN")}</h1>
              <p>{t("LOGIN_TO_YOUR_ACCOUNT")} </p>
            </div>

            <div className={styles.loginForm}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <CustomInputField
                  type="type"
                  PlaceHolder={t("ENTER_EMAIL_ADDRESS")}
                  RegisterName="EMAIL"
                  register={register}
                  formState={formState}
                  label={t("EMAIL_ADDRESS")}
                  onChange={(e) => {
                    setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                      shouldValidate: true,
                    });
                  }}></CustomInputField>
                <CustomPasswordInputField
                  label={t("PASSWORD")}
                  PlaceHolder={t("Enter Password")}
                  RegisterName="PASSWORD"
                  register={register}
                  formState={formState}
                  onChange={(e) => {
                    setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
                      shouldValidate: true,
                    });
                  }}
                />
                <div className={styles.forGotpassword}>
                  <Link to="/send-code"> {<p>{t("FORGOT_PASSWORD")}</p>}</Link>
                </div>

                <div className={styles.loginButtonmain}>
                  <Button
                    variant="primar"
                    type="submit"
                    className={styles.loginBtn}>
                    {t("LOGIN")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
