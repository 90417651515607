import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import styles from "../SettingPermission/Settingpermission.module.css";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AdminIndependicareApi from "../../Helpers/Api";
import { useSelector } from "react-redux";
import Toster from "../../Toster/Toster";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../Loader/Loader";
function TermsConditions() {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [page, setPage] = useState();
  const userToken = useSelector((state) => state.user.userToken);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (content, delta, source, editor) => {
    setValue(content);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    AdminIndependicareApi.privacyPolicy(userToken, "terms_conditions").then(
      (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setValue(response?.data?.content_data);
        }
      }
    );
  }, [page]);

  const handleSubmit = () => {
    const formdata = new FormData();
    formdata.append("page_name", "terms_conditions");
    formdata.append("page_data", value);
    setIsLoading(true);
    AdminIndependicareApi.updateprivacyPolicy(userToken, formdata).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("TERMS_CONDITIONS_UPDATE_SUCCESSFULLY"), "success");
          } else if (
            response?.code == STATUS_CODE.VALIDATION_ERROR ||
            response?.data?.code == STATUS_CODE.VALIDATION_ERROR
          ) {
            Toster(response?.code?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };
  return (
    <>
      <div className={styles.settingMainSection}>
        <div className={styles.mainSidebar}>
          <div className={styles.seetingLeftSidebar}>
            <Sidebar />
          </div>
          {/******************RightSide bar*****************/}
          <div className={styles.seetingRightSidebar}>
            <Header />
            <div className={styles.termsConditionstMain}>
              <div className={styles.termsConditionstText}>
                <h1>{t("TERMS_&_CONDITIONS")}</h1>
              </div>
              <div className={styles.privacyPolicyText}>
                <div>
                  {isLoading && <Loader />}
                  <ReactQuill
                    value={value}
                    onChange={handleChange}
                    placeholder="Start typing..."
                  />
                  <div className={styles.privacyBtnMain}>
                    <Button
                      variant=""
                      onClick={handleSubmit}
                      className={styles.privacyBtn}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsConditions;
