import React from "react";
import Router from "./Utils/Router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Router></Router>
        </I18nextProvider>
      </BrowserRouter>
      <ToastContainer transition={Slide} />
    </React.Fragment>
  );
}

export default App;
