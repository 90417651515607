import React, { useState, useEffect } from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
function Tag({ data, setPageNo }) {
  const { t } = useTranslation();
  const [stakeholdertaglist, setStackHolderTagList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setStackHolderTagList(data.tagList);
      const pages = Math.ceil(data.tagCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };
  return (
    <>
      {stakeholdertaglist?.length !== 0 ? (
        <div className={styles.taglist}>
          <Table striped bordered className={styles.stackDetailsTable}>
            <thead>
              <tr>
                <th>{t("TAG_UNIQ_ID")}</th>
                <th>{t("TAG_TITLE")}</th>
                <th>{t("PERSON_NAME")}</th>
                <th>{t("LOCATION_NAME")}</th>
                <th>{t("SUBLOCATION_TITLE")}</th>
                <th>{t("NOTES")}</th>
              </tr>
            </thead>
            <tbody>
              {stakeholdertaglist?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.tag_uniq_id}</td>
                    <td>{item.tag_title ? item.tag_title : "NA"}</td>
                    <td>{item.person_name ? item.person_name : "NA"}</td>
                    <td>{item.location_title ? item.location_title : "NA"}</td>
                    <td>
                      {item.sublocation_title ? item.sublocation_title : "NA"}
                    </td>
                    <td className="notestd">
                      <div className="notesSection">
                        {item.notes ? item.notes : "NA"}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
      )}

      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default Tag;
