import React, { useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import delete_icon from "../../assets/image/delete_icon.svg";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { userLogoutClear } from "../../redux/slices/userSlice";
import AddLocationTypeModel from "./AddLocationTypeModel";
import deleteimage from "../../assets/image/deleteimage.png";
import cancelBtn from "../../assets/image/cancelBtn.png";
import Modal from "react-bootstrap/Modal";

function DeleteLocationModel({
  deleteshow,
  handleDeleteClose,
  locationId,
  handleGetData,
  searchTerm,
}) {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const locationDelete = (id) => {
    setIsLoading(true);
    AdminIndependicareApi.deleteLocationType(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("DELETE_LOCATION_SUCCESSFULLY"), "success");
          handleGetData(searchTerm);
          handleDeleteClose();
        } else if (
          response.code == STATUS_CODE.VALIDATION_ERROR ||
          response.data.code === STATUS_CODE.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };
  return (
    <Modal show={deleteshow} onHide={handleDeleteClose}>
      <Modal.Header>
        <Modal.Title>{t("ALERT")}</Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleDeleteClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caregiverSourcePopup}>
          <img
            src={deleteimage}
            alt="deleteimage"
            className={styles.deleteImageModel}></img>
          <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_LOCATION?")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.addNewTitle}>
        <Button
          variant=""
          onClick={() => {
            locationDelete(locationId);
          }}>
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteLocationModel;
