import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminIndependicareApi from "../../Helpers/Api";
const initialState = {
  isLoading: false,
  userToken: null,
  profileImg: null,
  currentUser: {},
  userData: "",
  error: null,
};

export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdminIndependicareApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  "user/userProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdminIndependicareApi.fetchProfile(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state) => {
      state.userToken = null;
      state.profileImg = null;
    },
  },
  extraReducers: (builder) => {
    // user login=========================================
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.userToken = response?.data.token;
      state.currentUser = response?.data;

      state.profileImg = response?.data?.profile_img;
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user login=========================================
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      const response = action.payload;
      state.userData = response?.data?.adminDetails;
      state.profileImg = response?.data?.adminDetails?.profile_img;
    });

    builder.addCase(fetchUserProfile.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { userLogoutClear } = userSlice.actions;
export default userSlice.reducer;
