import React, { useEffect, useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import userLogoutClear from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import deleteimage from "../../assets/image/deleteimage.png";
function DeleteSubLocationModel({
  deleteshow,
  handleDeleteClose,
  sublocationId,
  subLocationList,
  searchTerm,
}) {
  const { register, handleSubmit, reset, formState, setValue, getValues } =
    useForm({
      mode: "onChange",
    });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // delete api call
  const sublocationDelete = (id) => {
    setIsLoading(true);
    AdminIndependicareApi.deletSubLocationType(userToken, id).then(
      (response) => {
        const ResponseCode = STATUS_MSG[response?.data.code];
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          if (response.code == STATUS_CODE.SUCCESS) {
            Toster(t("DELETE_SUB_LOCATION_SUCCESSFULLY"), "success");
            subLocationList(searchTerm);
            handleDeleteClose();
          } else if (
            response.code == STATUS_CODE.VALIDATION_ERROR ||
            response.data.code === STATUS_CODE.VALIDATION_ERROR
          ) {
            Toster(response.data.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };
  return (
    <Modal show={deleteshow} onHide={handleDeleteClose}>
      <Modal.Header>
        <Modal.Title>Alert</Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleDeleteClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caregiverSourcePopup}>
          <img
            src={deleteimage}
            alt="deleteimage"
            className={styles.deleteImageModel}></img>
          <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SUB_LOCATION?")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.addNewTitle}>
        <Button
          variant=""
          onClick={() => {
            sublocationDelete(sublocationId);
          }}>
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteSubLocationModel;
