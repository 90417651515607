import React, { useEffect, useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import { CustomInputField } from "../CustomInputField/CustomInputField";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import userLogoutClear from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
const AddLocationTypeModel = ({
  show,
  handleClose,
  isEdit,
  locationId,
  handleGetData,
  searchTerm,
}) => {
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // Add api CaregiverSources
  const onSubmit = (data) => {
    const UpdateUrl = `settings/update-location-type/${locationId}`;
    const AddUrl = "settings/location-type";
    let url;
    let method;
    if (locationId) {
      url = UpdateUrl;
      method = "put";
    } else {
      url = AddUrl;
      method = "post";
    }
    const PostData = new FormData();
    PostData.append("title", data.TITLE);
    setIsLoading(true);
    AdminIndependicareApi.addLocation(url, method, PostData, userToken).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            if (locationId) {
              Toster(t("LOCATION_UPDATED_SUCCESSFULLY"), "success");
            } else {
              Toster(t("ADD_LOCATION_SUCCESSFULLY"), "success");
              reset();
            }
            handleGetData(searchTerm);
            handleClose();
          } else if (
            response?.code == STATUS_CODE.VALIDATION_ERROR ||
            response?.data?.code == STATUS_CODE.VALIDATION_ERROR
          ) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  };

  // Detail api call
  useEffect(() => {
    if (isEdit && locationId) {
      AdminIndependicareApi.fetchLocationeDetail(userToken, locationId).then(
        (response) => {
          setValue("TITLE", response?.data?.location_type?.title);
          clearErrors("TITLE");
        }
      );
    } else {
      setValue("TITLE", "");
      clearErrors("TITLE", "");
    }
  }, [isEdit, locationId, show]);
  return (
    <Modal show={show} onHide={handleClose}>
      {isLoading && <Loader />}
      <Modal.Header>
        <Modal.Title>
          {isEdit ? t("UPDATE_LOCATION") : t("LOCATION_Add")}
        </Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <CustomInputField
            type="type"
            PlaceHolder={t("ENTER_TITLE")}
            RegisterName="TITLE"
            register={register}
            formState={formState}
            label={t("TITLE")}
            className={styles.modelPopupFiled}
            setValue={setValue}></CustomInputField>
        </Modal.Body>
        <Modal.Footer className={styles.addNewTitle}>
          <Button variant="" type="submit">
            {t("DONE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddLocationTypeModel;
