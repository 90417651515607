import React, { useEffect, useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import {
  CustomInputField,
  CustomSelect,
} from "../CustomInputField/CustomInputField";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
function AddSubLocation({
  show,
  handleClose,
  isEdit,
  sublocationId,
  handleChange,
  formattedOptions,
  selectedOption,
  subLocationList,
  setSelectedOption,
  searchTerm,
}) {
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = useSelector((state) => state.user.userToken);

  // add sublocationaand updatesub location api
  const onSubmit = (data) => {
    const UpdateUrl = `settings/update-sublocation-type/${sublocationId}`;
    const AddUrl = "settings/sublocation-type";
    let url;
    let method;
    if (sublocationId) {
      url = UpdateUrl;
      method = "put";
    } else {
      url = AddUrl;
      method = "post";
    }

    const PostData = new FormData();
    PostData.append("title", data.TITLE);
    PostData.append("location_id", data.Location);
    setIsLoading(true);
    AdminIndependicareApi.addSublocation(url, method, PostData, userToken).then(
      (response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data.code];
        if (response?.code == STATUS_CODE.SUCCESS) {
          if (sublocationId) {
            Toster(t("Update_SUB_LOCATION_SUCCESSFULLY"), "success");
          } else {
            Toster(t("ADD_SUB_LOCATION_SUCCESSFULLY"), "success");
            reset();
          }
          resetForm();
          subLocationList(searchTerm);
          handleClose();
        } else if (
          response?.code == STATUS_CODE.VALIDATION_ERROR ||
          response?.data?.code === STATUS_CODE.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };
  const resetForm = () => {
    reset();
    setSelectedOption(null);
  };

  useEffect(() => {
    if (isEdit && sublocationId) {
      AdminIndependicareApi.fetchSubLocationeDetail(
        userToken,
        sublocationId
      ).then((response) => {
        setValue("TITLE", response?.data?.sublocation_type?.title || "");
        setSelectedOption({
          value: response?.data?.sublocation_type?.location_type_id,
          label: response?.data?.sublocation_type?.location_title,
        });
        // Set the location value in the form
        setValue(
          "Location",
          response?.data?.sublocation_type?.location_type_id
        );
        clearErrors("TITLE");
      });
    } else {
      resetForm();
    }
  }, [show, isEdit, sublocationId]);
  return (
    <Modal show={show} onHide={handleClose}>
      {isLoading && <Loader />}
      <Modal.Header>
        <Modal.Title>
          {isEdit ? t("UPDATE_SUB_LOCATION") : t("ADD_SUB_LOCATION")}
        </Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <CustomSelect
            label={t("LOCATION")}
            RegisterName={"Location"}
            register={register}
            formState={formState}
            onChangeHandler={handleChange}
            PlaceHolder={t("Please Select Location")}
            options={formattedOptions}
            defaultValue=""></CustomSelect>
          <CustomInputField
            type="type"
            PlaceHolder={t("ENTER_TITLE")}
            RegisterName="TITLE"
            register={register}
            formState={formState}
            label={t("TITLE")}
            className={styles.modelPopupFiled}
            setValue={setValue}
          />
        </Modal.Body>
        <Modal.Footer className={styles.addNewTitle}>
          <Button variant="" type="submit">
            {t("DONE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddSubLocation;
