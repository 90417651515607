import i18next from "i18next";
import { I18nContext } from "react-i18next";
import i18n from "i18next";
import enTranslation from './locales/en.json'
import { initReactI18next } from "react-i18next";
const resources = {
    en: {
        translation: enTranslation,
    },
    //   es: {
    //     translation: esTranslation,
    //   },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en", // default language
    interpolation: {
        escapeValue: false, // react already safe from xss
    },
});

export default i18n;