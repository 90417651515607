export const ValidationSchema = {
  EMAIL: {
    required: "EMAIL_REQUIRED",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "VALID_EMAIL_ADDRESS",
    },
  },
  PASSWORD: {
    required: "PASSWORD_REQUIRE",
    // minLength: {
    //   value: 6,
    //   message: "PASSWORD_MUST_6_CH",
    // },
    // maxLength: {
    //   value: 12,
    //   message: "PASSWORD_NOT_MORE_THAN_12_CH",
    // },
    // pattern: {
    //   value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
    //   message: "PASSWORD_MUST_CONTAIN",
    // },
  },
  PASSCODE: {
    required: "PASSCODE_REQUIRED",
    minLength: {
      value: 4,
      message: "PASSCODE_MUST_4_CH",
    },
    maxLength: {
      value: 4,
      message: "PASSCODE_NOT_MORE_THAN_4_CH",
    },
  },
  OLD_PASSWORD: {
    required: "OLD_PASSWORD",
  },

  NEW_PASSWORD: {
    required: "NEW_PASSWORD",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },

  Location: {
    required: "PLEASE_SELECT_LOATION_ID",
  },

  CONFIRM_PASSWORD: {
    required: "CONFIRM_PASSWORD",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "PASSWORD_MUST_CONTAIN",
    },
    validate: (value, formData) =>
      value === formData.PASSWORD || "PASSWORD_NOT_MATCHED",
  },

  confirmPasswordProfile: {
    required: "PASSWORD_NOT_MATCHED",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@])/,
      message: "PASSWORD_MUST_CONTAIN",
    },
    validate: (value, formData) =>
      value === formData.NEW_PASSWORD || "PASSWORD_NOT_MATCHED",
  },
  TITLE: {
    required: "TITLE_REQUIRED",
    minLength: {
      value: 2,
      message: "NAME_MAY_HAVE_MINIMUM_OF_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "NAME_MAY_HAVE_MAXIMUM_OF_50_CHARACTERS",
    },
  },

  FullName: {
    required: "FULL_NAME_REQUIRED",
    minLength: {
      value: 2,
      message: "NAME_MAY_HAVE_MINIMUM_OF_2_CHARACTERS",
    },
    maxLength: {
      value: 50,
      message: "NAME_MAY_HAVE_MAXIMUM_OF_50_CHARACTERS",
    },
  },
};
