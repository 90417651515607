import React, { useEffect, useState, useCallback } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Table, DropdownButton } from "react-bootstrap";
import arrow from "../../assets/image/arrow-back.png";
import horizontal from "../../assets/image/horizontal.png";
import search from "../../assets/image/search.png";
import { Form, Button } from "react-bootstrap";
import styles from "../Stakeholderslist/Stakeholderslist.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import delete_icon from "../../assets/image/delete_icon.svg";
import { useNavigate } from "react-router-dom";
import AdminIndependicareApi from "../../Helpers/Api";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Loader from "../Loader/Loader";
import ReactPaginate from "react-paginate";
import Toster from "../../Toster/Toster";
import debounce from "debounce";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Action_icon from "../../assets/image/Action_icon.png";
import Modal from "react-bootstrap/Modal";
import deleteimage from "../../assets/image/deleteimage.png";
import cancelBtn from "../../assets/image/cancelBtn.png";
import StakeholderStatus from "./StakeholderStatus";
function Stakeholderslist() {
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [holderList, setHolderList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageno, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  const [stackHolderId, setstackHolderId] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /***status popup */
  const [statusshow, setStatusShow] = useState(false);
  const handleStatusClose = () => setStatusShow(false);
  const handleStatusShow = () => setStatusShow(true);
  const [satckholderStatus, setSatckHolderStatus] = useState(false);

  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  const [isLoading, setIsLoading] = useState(false);
  const stackHolder = (pageno, searchTerm) => {
    setIsLoading(true);
    AdminIndependicareApi.fetchStakeholderList(
      userToken,
      pageno,
      searchTerm
    ).then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setHolderList(response?.data?.usersList);
        setTotalCount(response?.data?.count);
      }
    });
  };

  useEffect(() => {
    stackHolder(pageno, searchTerm);
  }, [pageno]);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };

  const debouncedStackHolder = useCallback(
    debounce((pageno, searchTerm) => {
      stackHolder(pageno, searchTerm);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    debouncedStackHolder(1, newSearchTerm);
  };
  // fetch detail api
  const deleteStackHolder = (id) => {
    AdminIndependicareApi.deletStackholder(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("STACKHOLDER_DELETE_SUCCESSFULLY"), "success");
          stackHolder(pageno, searchTerm);
          handleClose();
        } else if (
          response.code == STATUS_CODE.VALIDATION_ERROR ||
          response.data.code === STATUS_CODE.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
        } else {
          const message = t(ResponseCode).replace("/Caregiver", "");
          Toster(message, "error");
        }
      }
    });
  };
  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className={styles.stakeHolderListMain}>
        <div className={styles.stackarListMain}>
          <div className={styles.stackInner}>
            <div className={styles.arrowImage}>
              <NavLink to="/home">
                <img src={arrow} alt="arrow"></img>
              </NavLink>
            </div>
            <div className={styles.stakeHoldersText}>
              <h1>{t("STAKEHOLDERS")}</h1>
            </div>
          </div>
          <div>
            <Form>
              <div className={styles.serchIconImage}>
                <Form.Control
                  size="sm"
                  type="search"
                  placeholder={t("PLACRHOLDER_SEARCH_BY_NAME_EMAIL")}
                  value={searchTerm}
                  onChange={handleInputChange}
                />
                <img
                  src={search}
                  alt="serchIcon"
                  className={styles.settingSerchIcon}></img>
              </div>
            </Form>
          </div>
        </div>
        {holderList?.length !== 0 ? (
          <div className={styles.tableList}>
            <div className={styles.tableheight}>
              <Table striped bordered>
                {isLoading && <Loader />}
                <thead className={styles.tableThText}>
                  <tr>
                    <th className={styles.stakholderList}> {t("NAME")}</th>
                    <th className={styles.stakholderList}>{t("EMAIL")}</th>
                    <th className={styles.stakholderList}>
                      {t("PHONE_NUMBER")}
                    </th>
                    <th className={styles.stakholderList}>
                      {t("STREET_ADDRESS")}
                    </th>
                    <th className={styles.stakholderList}>{t("CITY")}</th>
                    <th className={styles.stakholderList}>{t("STATE_NAME")}</th>
                    <th className={styles.stakholderList}>
                      {t("COUNTRY_NAME")}
                    </th>
                    <th className={styles.stakholderList}>{t("STATUS")}</th>
                    <th className={styles.stakholderList}>{t("ACTION")}</th>
                  </tr>
                </thead>
                <tbody className={styles.tableTrtext}>
                  {holderList?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.textuppercase}>{item.name}</td>
                        <td>{item.email}</td>
                        <td>
                          {item.dial_code} {item.phone_number}
                        </td>
                        <td className={styles.textuppercase}>
                          {item.street_address}
                        </td>
                        <td className={styles.textuppercase}>{item.city}</td>
                        <td>{item.state_name}</td>
                        <td>{item.country_name}</td>
                        <td>
                          {" "}
                          {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td>
                          <DropdownButton
                            id=""
                            className={styles.customBtn}
                            title={
                              <img
                                src={horizontal}
                                className={styles.doteImage}
                                alt="horizontal"></img>
                            }>
                            <Dropdown.Item
                              onClick={() => {
                                navigate(`/stakeholderdetails/${item.id}`);
                              }}
                              className={styles.editIcon}>
                              <span>{t("VIEW")}</span>{" "}
                              <img
                                src={Action_icon}
                                alt="Logo"
                                className="logoedit"
                              />
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={styles.deleteIcon}
                              onClick={() => {
                                handleShow();
                                setstackHolderId(item.id);
                              }}>
                              <span>{t("DELETE")}</span>
                              <img
                                src={delete_icon}
                                alt="Logo"
                                className="logo"
                              />
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              onClick={() => {
                                handleStatusShow();
                                setstackHolderId(item.id);
                                setSatckHolderStatus(item.is_active);
                              }}
                              className={styles.caregiverdropdownItem}>
                              <span>
                                {item.is_active === 1 ? "Inactive" : "active"}
                                {/* {t("STATUS")} */}
                              </span>
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            {totalPageno > 1 && (
              <div className={styles.paginationContainer}>
                <ReactPaginate
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  breakLabel="..."
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  pageCount={totalPageno} // Adjust this as per your total page count
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        ) : (
          !isLoading && (
            <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
          )
        )}
        {/*********delete popup model******* */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{t("ALERT")}</Modal.Title>
            <img
              src={cancelBtn}
              onClick={handleClose}
              className={styles.cancelBtn}></img>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.caregiverSourcePopup}>
              <img
                src={deleteimage}
                alt="deleteimage"
                className={styles.deleteImageModel}></img>
              <p>
                {t(
                  "CAN'T_DELETE_STAKEHOLDER_IS_ASSIGNED_SENIOR_PERSON_TO_CAREGIVER"
                )}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.stackHolderButton}>
            <Button
              variant=""
              onClick={() => {
                deleteStackHolder(stackHolderId);
              }}>
              {t("OK")}
            </Button>
          </Modal.Footer>
        </Modal>
        <StakeholderStatus
          statusshow={statusshow}
          handleStatusClose={handleStatusClose}
          stackHolder={stackHolder}
          satckholderStatus={satckholderStatus}
          stackHolderId={stackHolderId}
          searchTerm={searchTerm}
          pageno={pageno}
        />
      </div>

      <Footer />
    </>
  );
}
export default Stakeholderslist;
