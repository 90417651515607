import React, { useEffect, useState } from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
function Event({ data, setPageNo }) {
  const { t } = useTranslation();
  const [events, setEventsList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setEventsList(data.eventList);
      const pages = Math.ceil(data.eventCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };

  // const formatDateTo12Hour = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleTimeString([], {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: true,
  //   });
  // };
  return (
    <>
      {events?.length !== 0 ? (
        <div className={styles.evntType}>
          <Table striped bordered hover className={styles.stackDetailsTable}>
            <thead>
              <tr>
                <th>{t("DATE")}</th>
                <th>{t("TIME")}</th>
                <th>{t("PERSON_NAMES")}</th>
                <th>{t("REMINDER")}</th>
              </tr>
            </thead>
            <tbody>
              {events?.map((item, index) => (
                <tr key={index}>
                  {/* <td>{item.date}</td> */}
                  <td>{format(new Date(item.date), "MM/dd/yyyy")}</td>
                  <td>{item.time}</td>
                  <td>{item.person_names}</td>
                  <td className="notestd">
                    <div className="notesSection">
                      {item.reminder == "" || item.reminder == null
                        ? item.custom_reminder
                        : item.reminder}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
      )}
      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default Event;
