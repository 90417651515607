import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import styles from "../Login/Login.module.css";
import logo from "../../assets/image/logo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import {
  CustomInputField,
  CustomPasswordInputField,
} from "../CustomInputField/CustomInputField";
import { useForm, Controller } from "react-hook-form";
import AdminIndependicareApi from "../../Helpers/Api";
import Toster from "../../Toster/Toster";
import Vector from "../../assets/image/Vector.png";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Loader from "../Loader/Loader";
function ResetPassword() {
  const { register, handleSubmit, reset, formState, setValue, control } =
    useForm({
      mode: "onChange",
    });

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const userEmail = location.state.userEmail;
  const passCodeValidation = {
    required: "PASSCODE_REQUIRED",
    pattern: {
      value: /^[0-9]{4}$/,
      message: "ENTER_VALID_PASSCODE",
    },
    minLength: {
      value: 4,
      message: "PASSCODE_MUST_4_CH",
    },
    maxLength: {
      value: 4,
      message: "PASSCODE_NOT_MORE_THAN_4_CH",
    },
  };
  const onSubmit = async (formdata) => {
    const PostData = new FormData();
    PostData.append("email", userEmail);
    PostData.append("pass_code", formdata.PASSCODE);
    PostData.append("password", formdata.PASSWORD);
    PostData.append("confirm_password", formdata.CONFIRM_PASSWORD);
    setIsLoading(true);
    AdminIndependicareApi.resetPassword(PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        Toster(t("PASSWORD_UPDATED_SUCCESSFULLY"), "success");
        navigate("/");
        reset();
      } else if (
        response?.data?.code == STATUS_CODE.VALIDATION_ERROR ||
        response?.data?.code == STATUS_CODE.VALIDATION_ERROR
      ) {
        Toster(response?.message || response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.loginContainer}>
        <div className={styles.loginIndependiCareLogo}>
          <div className={styles.loginImage}>
            <img
              src={logo}
              className={styles.Loginimage}
              alt="independiCareLogo"></img>
          </div>
          <div className={styles.loginFrombackground}>
            <div className={styles.fotGotepassowtdtext}>
              <h1>{t("RESET_PASSWORD")}</h1>
              <p>{t("PLEASE_FILL_OUT_THIS_FROM_TO_REST_YOUR_PASSWORD")} </p>
            </div>

            <div className={styles.forgotPasswordFiled}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.userOtpContainerProperty}>
                  <Controller
                    name="PASSCODE"
                    control={control}
                    rules={passCodeValidation}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <OtpInput
                          value={field.value}
                          onChange={field.onChange}
                          numInputs={4}
                          isInputNum={true}
                          shouldAutoFocus
                          renderSeparator={<span></span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              className={`${styles.otpInputField} ${
                                error ? "is-invalid" : ""
                              }`}
                              placeholder="x"
                            />
                          )}
                        />

                        {error && (
                          <div className={styles.errorMessage}>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block">
                              {t(error.message)}
                            </Form.Control.Feedback>
                          </div>
                        )}
                        {/* </div> */}
                      </>
                    )}
                  />
                </div>
                {/* <CustomInputField
                  type="type"
                  PlaceHolder={t("PASSCODE")}
                  RegisterName="PASSCODE"
                  register={register}
                  formState={formState}
                  label={t("PASSCORD_LABEL")}
                  onChange={(e) => {
                    setValue("PASSCODE", e.target.value.replace(/\s/g, ""), {
                      shouldValidate: true,
                    });
                  }}></CustomInputField> */}

                <CustomPasswordInputField
                  label={t("PASSWORD")}
                  PlaceHolder={t("ENTER_PASSWORD")}
                  RegisterName="PASSWORD"
                  register={register}
                  formState={formState}
                  onChange={(e) => {
                    setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
                      shouldValidate: true,
                    });
                  }}
                />

                <CustomPasswordInputField
                  label={t("CONFIRM_PASSWORD")}
                  PlaceHolder={t("ENTER_CONFIRM_PASSWORD")}
                  RegisterName="CONFIRM_PASSWORD"
                  register={register}
                  formState={formState}
                  onChange={(e) => {
                    setValue(
                      "CONFIRM_PASSWORD",
                      e.target.value.replace(/\s/g, ""),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                />
                <div className={styles.backButtonmain}>
                  <div className={styles.backButtonLogin}>
                    <div className={styles.backArrow}>
                      <img src={Vector} alt="vector"></img>
                    </div>
                    <div className={styles.backSingText}>
                      <Link to="/">
                        <p>{t("BACK_TO_SING_IN")}</p>
                      </Link>
                    </div>
                  </div>

                  <Button type="submit" className={styles.sendLinkBtn}>
                    {t("RESET_PASSWORD")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
