import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { NavLink } from "react-router-dom";
import { CustomInputField } from "../CustomInputField/CustomInputField";
import search from "../../assets/image/search.png";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import arrow from "../../assets/image/arrow-back.png";
import { Table } from "react-bootstrap";
import horizontal from "../../assets/image/horizontal.png";
import arrowUpImage from "../../assets/image/arrowUpImage.png";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Sidebar from "../Sidebar/Sidebar";
import cancelBtn from "../../assets/image/cancelBtn.png";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import CaregiverSources from "./CaregiverSources";
function SettingPermission() {
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userToken = useSelector((state) => state.user.userToken);
  /// add api Caregiver soureces
  // const onSubmit = (data) => {
  //   const PostData = new FormData();
  //   PostData.append("name", data.TITLE);
  //   AdminIndependicareApi.caregiverSource(PostData, userToken).then(
  //     (response) => {
  //       // console.log("response", response);
  //     }
  //   );
  // };
  return (
    <>
      <div className={styles.settingMainSection}>
        <div className={styles.mainSidebar}>
          <div className={styles.seetingLeftSidebar}>
            <Sidebar />
          </div>
          {/******************RightSide bar*****************/}
          <div className={styles.seetingRightSidebar}>
            <Header />
            {/* <div className={styles.sechSeetingmain}>
              <div className={styles.seetingArrowImage}>
                <NavLink to="/home">
                  <img src={arrow} className={styles.seetingBackArrow}></img>
                </NavLink>
                <div className={styles.serchIconImage}>
                  <Form.Control
                    size="sm"
                    type="search"
                    placeholder={t("PLACRHOLDER_SEARCH")}
                  />
                  <img
                    src={search}
                    alt="serchIcon"
                    className={styles.settingSerchIcon}></img>
                </div>
              </div>
              <div className={styles.addNewButton}>
                <Button variant="" type="submit" onClick={handleShow}>
                  {t("ADD_NEW")}
                </Button>
              </div>
            </div> */}

            {/* <div className={styles.settingPermissionTable}>
              <Table className="customTable" striped bordered responsive>
                <thead>
                  <tr>
                    <th className={styles.settingBackground}>{t("TITLE")}</th>
                    <th className={styles.settingBackgroundAction}>
                      {t("ACTION")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={styles.caregiverSourceText}>Independent</td>
                    <td className={styles.settingDotImage}>
                      <img
                        src={horizontal}
                        className={styles.doteImage}
                        alt="horizontal"></img>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.caregiverSourceText}>Agency</td>
                    <td className={styles.settingDotImage}>
                      <img
                        src={horizontal}
                        className={styles.doteImage}
                        alt="horizontal"></img>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.caregiverSourceText}>Individual</td>
                    <td className={styles.settingDotImage}>
                      <img
                        src={horizontal}
                        className={styles.doteImage}
                        alt="horizontal"></img>{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div> */}
          </div>
        </div>
      </div>
      {/*********Add Title Popup Model******* */}
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t("ADD_NEW")}</Modal.Title>
          <img
            src={cancelBtn}
            onClick={handleClose}
            className={styles.cancelBtn}></img>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <CustomInputField
              type="type"
              PlaceHolder={t("ENTER_TITLE")}
              RegisterName="TITLE"
              register={register}
              formState={formState}
              label={t("TITLE")}
              className={styles.modelPopupFiled}
              onChange={(e) => {
                setValue("TITLE", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
              }}></CustomInputField>
          </Modal.Body>
          <Modal.Footer className={styles.addNewTitle}>
            <Button variant="" type="submit">
              {t("DONE")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Footer /> */}
    </>
  );
}

export default SettingPermission;
