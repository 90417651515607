import React, { useEffect, useState } from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import ReactAudioPlayer from "react-audio-player";

import Loader from "../Loader/Loader";
import Audio from "../../assets/image/Audio.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";
function Person({ data, setPageNo }) {
  const { t } = useTranslation();
  const [personlist, setPersonList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  const [showModal, setShowModal] = useState(false);
  const [renderTitle, setRenderTitle] = useState("");
  const [audio, setAudio] = useState("");
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setPersonList(data.personList);
      const pages = Math.ceil(data.personCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };

  const handleAudioToggleIntro = (audioUrl, text) => {
    setAudio(audioUrl);
    setRenderTitle(text);
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setAudio(null); // Stop the audio when modal is closed
    setRenderTitle("");
  };

  return (
    <>
      <div className={styles.personlist}>
        {isLoading && <Loader />}
        {personlist?.length !== 0 ? (
          <div>
            <Table
              className={`${styles.stackDetailsTable} hdeo`}
              striped
              bordered>
              <thead>
                <tr>
                  <th>{t("PERSON_NAME")}</th>
                  <th>{t("ALERT_TONE_AUDIO")}</th>
                  <th>{t("PROMPT_INTRO_TEXT")}</th>
                  <th>{t("PROMPT_INTRO_AUDIO")}</th>
                  <th>{t("LANGUAGE")}</th>
                  <th>{t("PROMPT_OPTION")}</th>
                  <th className="demo" style={{ minWidth: "100px" }}>
                    {t("NOTES")}
                  </th>
                  <th className="profileImage">{t("PROFILE_IMAGE")}</th>
                </tr>
              </thead>
              <tbody>
                {personlist?.map((item, index) => (
                  <tr key={index}>
                    <td className={styles.personName}>{item.person_name}</td>
                    <td>
                      {(item.alert_tone_audio && (
                        <>
                          <button
                            onClick={() =>
                              handleAudioToggleIntro(
                                item.alert_tone_audio,
                                "Alert tone autio"
                              )
                            }
                            className={styles.audioButton}
                            style={{ border: "none", background: "none" }} // Remove button styles
                          >
                            <img
                              src={Audio}
                              className={styles.audioAlertImage}
                              alt="audio"
                            />
                          </button>
                        </>
                      )) ||
                        "NA"}
                    </td>

                    <td>
                      {item.prompt_intro_text ? item.prompt_intro_text : "NA"}
                    </td>
                    <td>
                      {(item.prompt_intro_audio && (
                        <>
                          <button
                            onClick={() =>
                              handleAudioToggleIntro(
                                item.prompt_intro_audio,
                                "Prompt into audio"
                              )
                            }
                            className={styles.audioButton}
                            style={{ border: "none", background: "none" }} // Remove button styles
                          >
                            <img
                              src={Audio}
                              className={styles.audioAlertImage}
                              alt="audio"
                            />
                          </button>
                        </>
                      )) ||
                        "NA"}
                    </td>
                    <td className={styles.personName}>{item.language}</td>
                    <td className={styles.personName}>{item.prompt_option}</td>
                    <td className="notestd">
                      <div className="notesSection">
                        {item.notes ? item.notes : "NA"}
                      </div>
                    </td>
                    <td>
                      <img
                        src={item.profile_img}
                        alt={item.person_name}
                        className={styles.personProfileImage}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
        )}
      </div>
      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}

      {/****popupmodel*** */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{renderTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {audio && (
            <ReactAudioPlayer
              src={audio}
              controls
              autoPlay
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Person;
