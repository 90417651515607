import React, { useEffect, useState, useCallback } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import styles from "../SettingPermission/Settingpermission.module.css";
import arrow from "../../assets/image/arrow-back.png";
import horizontal from "../../assets/image/horizontal.png";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import search from "../../assets/image/search.png";
import { NavLink, useNavigate } from "react-router-dom";
import delete_icon from "../../assets/image/delete_icon.svg";
import Edit from "../../assets/image/Edit.svg";
import { useForm } from "react-hook-form";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { Dropdown, Table, DropdownButton } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { userLogoutClear } from "../../redux/slices/userSlice";
import AddLocationTypeModel from "./AddLocationTypeModel";
import DeleteLocationModel from "./DeleteLocationModel";
import StatusLocationModel from "./StatusLocationModel";
import deleteimage from "../../assets/image/deleteimage.png";
import cancelBtn from "../../assets/image/cancelBtn.png";
import Modal from "react-bootstrap/Modal";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
import Footer from "../Footer/Footer";
import debounce from "debounce";

function LocationType() {
  const { handleSubmit, reset, formState, setValue, getValues } = useForm({
    mode: "onChange",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [location, setLocation] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [locationId, setLocationId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // chnageStaus popup funtion
  const [statusshow, setStatusShow] = useState(false);
  const handleStatusClose = () => setStatusShow(false);
  const handleStatusShow = () => setStatusShow(true);
  const [locationStatus, setLocationStatus] = useState(false);
  // delete popup funtion
  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  // serch bar states
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    handleGetData(searchTerm);
  }, []);

  const debouncedStackHolder = useCallback(
    debounce((searchTerm) => {
      handleGetData(searchTerm);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    debouncedStackHolder(newSearchTerm);
  };

  // get apicall
  const handleGetData = (searchTerm) => {
    setIsLoading(true);
    AdminIndependicareApi.fetchLocationType(userToken, searchTerm).then(
      (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setLocation(response?.data?.loaction_type);
        }
      }
    );
  };
  // deail api call
  useEffect(() => {
    if (isEdit && locationId) {
      AdminIndependicareApi.fetchLocationeDetail(userToken, locationId).then(
        (response) => {
          setValue("TITLE", response?.data?.location_type?.title);
        }
      );
    } else {
      setValue("TITLE", "");
    }
  }, [isEdit, locationId]);
  return (
    <>
      <div className={styles.settingMainSection}>
        {isLoading && <Loader />}
        <div className={styles.mainSidebar}>
          <div className={styles.seetingLeftSidebar}>
            <Sidebar />
          </div>
          {/******************RightSide bar*****************/}
          <div className={styles.seetingRightSidebar}>
            <Header />
            <div className={styles.sechSeetingmain}>
              <div className={styles.seetingArrowImage}>
                <NavLink to="/setting-permission">
                  <img src={arrow} className={styles.seetingBackArrow}></img>
                </NavLink>
                <div className={styles.serchIconImage}>
                  <Form.Control
                    size="sm"
                    type="search"
                    placeholder={t("PLACRHOLDER_SEARCH")}
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                  <img
                    src={search}
                    alt="serchIcon"
                    className={styles.settingSerchIcon}></img>
                </div>
              </div>
              <div className={styles.addNewButton}>
                <Button
                  variant=""
                  type="submit"
                  onClick={() => {
                    handleShow();
                    setIsEdit(false);
                    setLocationId();
                  }}>
                  {t("ADD_NEW")}
                </Button>
              </div>
            </div>
            {location?.length !== 0 ? (
              <div className={styles.settingPermissionTable}>
                <Table className="customTable" striped bordered>
                  {isLoading && <Loader />}
                  <thead>
                    <tr>
                      <th className={styles.settingBackground}>{t("TITLE")}</th>
                      <th className={styles.settingBackground}>
                        {t("STATUS")}
                      </th>
                      <th className={styles.settingBackgroundAction}>
                        {t("ACTION")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {location?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className={styles.caregiverSourceText}>
                            {item.title}
                          </td>
                          <td className={styles.caregiverSourceText}>
                            {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                              ? "active"
                              : "inactive"}
                          </td>
                          <td className={styles.settingDotImage}>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className={styles.doteImage}>
                                <img
                                  src={horizontal}
                                  alt="horizontal"
                                  className={styles.careGiverDoteImage}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="#"
                                  className={styles.caregiverdropdownItem}
                                  onClick={() => {
                                    handleShow(index);
                                    setIsEdit(true);
                                    setLocationId(item.id);
                                  }}>
                                  <span>{t("EDIT")}</span>
                                  <img
                                    src={Edit}
                                    alt="Edit"
                                    className={styles.icon}
                                  />
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  className={styles.caregiverdropdownItem}
                                  onClick={() => {
                                    handleDeleteShow();
                                    setLocationId(item.id);
                                  }}>
                                  <span>{t("DELETE")}</span>
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    className={styles.icon}
                                  />
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => {
                                    handleStatusShow();
                                    setLocationId(item.id);
                                    setLocationStatus(item.is_active);
                                  }}
                                  className={styles.caregiverdropdownItem}>
                                  <span>
                                    {item.is_active === 1
                                      ? "inactive"
                                      : "active"}
                                    {/* {t("STATUS")} */}
                                  </span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              !isLoading && (
                <p className={styles.listRecoredText}>
                  {t("RECORD_NOT_FOUND")}
                </p>
              )
            )}
          </div>
        </div>
      </div>
      {/*********Add Title Popup Model******* */}
      <AddLocationTypeModel
        show={show}
        handleClose={handleClose}
        handleGetData={handleGetData}
        isEdit={isEdit}
        locationId={locationId}
        searchTerm={searchTerm}
      />
      {/**********delete popupModel */}
      <DeleteLocationModel
        deleteshow={deleteshow}
        handleDeleteClose={handleDeleteClose}
        locationId={locationId}
        handleGetData={handleGetData}
        searchTerm={searchTerm}
      />
      {/********chnage the staus active inactive */}
      <StatusLocationModel
        statusshow={statusshow}
        handleStatusClose={handleStatusClose}
        handleGetData={handleGetData}
        locationId={locationId}
        locationStatus={locationStatus}
        searchTerm={searchTerm}
      />
      <Footer />
    </>
  );
}

export default LocationType;
