import React, { useState, useEffect } from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
function Site({ data, setPageNo }) {
  const { t } = useTranslation();
  const [sitelist, setSiteList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setSiteList(data.siteList);
      const pages = Math.ceil(data.siteCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };

  // function truncateText(text, wordLimit) {
  //   const words = text.split(" ");
  //   if (words.length > wordLimit) {
  //     return words.slice(0, wordLimit).join(" ") + "...";
  //   }
  //   return text;
  // }
  return (
    <>
      {isLoading && <Loader />}
      {sitelist?.length !== 0 ? (
        <div className="siteListMain">
          <div className={styles.siteList}>
            <Table
              striped
              bordered
              // responsive
              className={styles.stackDetailsTable}>
              {isLoading && <Loader />}
              <thead>
                <tr>
                  <th>{t("SITE_ID")}</th>
                  <th>{t("SITE_TITLE")}</th>
                  <th>{t("START_DATE")}</th>
                  <th>{t("REPRESENTATIVE")}</th>
                  <th>{t("PERSON_NAME")}</th>
                  <th>{t("DEVICE_ID_NAMES")}</th>
                  <th>{t("RADER_LOCATION")}</th>
                  <th>{t("NOTES")}</th>
                </tr>
              </thead>
              <tbody>
                {sitelist?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.site_uid ? item.site_uid : "NA"}</td>
                      <td className={styles.personName}>{item.site_title}</td>
                      <td className={styles.siteDateResposive}>
                        {format(new Date(item.start_date), "MM/dd/yyyy")}
                      </td>
                      <td className={styles.personName}>
                        {item.representative ? item.representative : "NA"}
                      </td>
                      <td>{item.person_names ? item.person_names : "NA"}</td>
                      <td>
                        {item.device_id_names ? item.device_id_names : "NA"}
                      </td>
                      {/* <td>{item.tag_names}</td> */}
                      <td>
                        {item.reader_location ? item.reader_location : "NA"}
                      </td>
                      <td className="notestd">
                        <div className="notesSection">
                          {item.notes ? item.notes : "NA"}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
      )}
      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}
export default Site;
