import React, { useEffect, useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import {
  CustomInputField,
  CustomInputFields,
} from "../CustomInputField/CustomInputField";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import userLogoutClear from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
const AddCaregiverSources = ({
  show,
  handleClose,
  isEdit,
  handleGetData,
  resourceId,

  // reset,
}) => {
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    formState,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // Add api CaregiverSources

  const onSubmit = (data) => {
    const UpdateUrl = `settings/update-source-details/${resourceId}`;
    const AddUrl = "settings/caregiver-source";
    let url;
    let method;
    if (resourceId) {
      url = UpdateUrl;
      method = "put";
    } else {
      url = AddUrl;
      method = "post";
    }
    const PostData = new FormData();
    setIsLoading(true);
    PostData.append("name", data.TITLE);
    AdminIndependicareApi.caregiverSource(
      url,
      method,
      PostData,
      userToken
    ).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data.code];
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        if (response?.code == STATUS_CODE.SUCCESS) {
          if (resourceId) {
            Toster(t("CAREGIVER_SOURCES_UPDATED_SUCCESSFULLY"), "success");
          } else {
            Toster(t("ADD_CAREGIVER_SOURCES_SUCCESSFULLY"), "success");
          }
          handleClose();
          handleGetData();
          reset();
        } else if (
          response?.code == STATUS_CODE.VALIDATION_ERROR ||
          response?.data?.code === STATUS_CODE.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };
  // Detail api call
  useEffect(() => {
    if (isEdit && resourceId) {
      AdminIndependicareApi.fetchCaregiverSourceDetail(
        userToken,
        resourceId
      ).then((response) => {
        setValue("TITLE", response?.data?.caregiver_source?.name);
        clearErrors("TITLE");
      });
    } else {
      setValue("TITLE", "");
      clearErrors("TITLE");
    }
  }, [isEdit, resourceId, show]);

  return (
    <Modal show={show} onHide={handleClose}>
      {isLoading && <Loader />}
      <Modal.Header>
        <Modal.Title>
          {isEdit ? t("UPDATE_CAREGIVER_SOURCES") : t("ADD_CAREGIVER_SOURCES")}
        </Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <CustomInputField
            type="text"
            PlaceHolder={t("ENTER_TITLE")}
            RegisterName="TITLE"
            register={register}
            formState={formState}
            label={t("TITLE")}
            setValue={setValue}
          />
        </Modal.Body>
        <Modal.Footer className={styles.addNewTitle}>
          <Button variant="" type="submit">
            {t("DONE")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddCaregiverSources;
